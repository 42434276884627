<template>
  <div>
    <VTitle :title="$t('app.details')" class="section-title" />

    <Form
      :id="id"
      :is-loading="isLoading"
      :data="data"
      @click:cancel="$emit('click:cancel')"
      @click:save="$emit('click:save', $event)"
      @refresh="$emit('refresh')"
    />

    <Logs :data="data" class="mt-8" hide-action />
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";
import useRegistration from "@/composables/useRegistration";
// Components
import VTitle from "@/components/VTitle";
import Logs from "@/components/Logs";
import Form from "./Form";

export default {
  components: {
    VTitle,
    Form,
    Logs
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:cancel", "click:save", "refresh"],
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Computed
    const data = computed(() => props.resource);

    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.details")} - ${t("app.trainings", 2)} - ${t("app.registrations", 2)} - ${t("app.teaching")}`;

      const firstname = data?.value?.learner?.firstname;
      const lastname = data?.value?.learner?.lastname;
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    const isActive = computed(() => {
      return data.value.status === ALL_OPTIONS.ACTIVE.value;
    });

    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { endpoint } = useRegistration();

    return {
      documentTitle,
      data,
      isActive,
      // useRegistration
      endpoint
    };
  }
};
</script>
